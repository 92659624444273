<script>
    import Layout from "../../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Monitor from "@/services/Monitor";

    export default {
        components: { Layout, PageHeader },
        page: {
            title: "API Exceptions",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "Exceptions",
                items: [
                    {
                        text: "Logs",
                    },
                    {
                        text: "Exceptions",
                        active: true,
                    },
                ],
                isBusy: false,
                queuesData: {},
                showLoader:false,
                error: '',
                tableData: [],
                totalRows: 0,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "timestamp",
                sortDesc: true,
                fields: [
                    {
                        key: "log_id",
                        label: "ID",
                        sortable: true
                    },
                    {
                        key: "version",
                        label: "Version",
                        sortable: true
                    },
                    {
                        key: "ip_address",
                        label: "Ip",
                        sortable: false
                    },
                    {
                        key: "status",
                        label: "Status",
                        sortable: true
                    },
                    {
                        key: "input_header",
                        label: "Input",
                        sortable: false
                    },
                    {
                        key: "response",
                        label: "Response",
                        sortable: false
                    },
                    {
                        key: "exec_time",
                        label: "Exec Time (ms)",
                        sortable: false,
                        class: 'float-end'
                    },
                    {
                        key: "timestamp",
                        label: "Timestamp",
                        sortable: true
                    },
                    "action",
                ]
            };
        },
        destroyed() {
            clearInterval(this.timer);
        },
        middleware: "authentication",
        async mounted() {
            try {
                await this.getExceptions();
                this.setupInterval();
            } catch (error) {
                console.error('Error during component initialization:', error);
            }
        },
        methods: {
            async getExceptions() {
                try {
                    this.filterData = true;
                    this.toggleBusy();
                    const response = await Monitor.getExceptions()
                    this.tableData = response.data.data
                    this.totalRows = this.tableData.length
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = []
                    this.totalRows = 0;
                } finally {
                    this.toggleBusy();
                }
            },

            setupInterval() {
                this.timer = setInterval(async () => {
                    try {
                        await this.getExceptions();
                    } catch (error) {
                        console.error('Error in interval:', error);
                    }
                },120000);
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
        }
    };
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-end"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :busy="isBusy"
                                    :items="tableData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    empty-text="No Data Found"
                                    @filtered="onFiltered"
                            >

                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                                <template v-slot:cell(log_id)="tableData">
                                     <span v-if="tableData.item.log_id && tableData.item.log_id.length > 20" class="text-nowrap">
                                        {{ tableData.item.log_id.slice(0, 20) + '... ' }}
                                        <a v-b-popover.hover.html.bottom="tableData.item.log_id" class="elementPointed float-end" title="Input" size="sm"><i class="uil uil-eye font-size-18"></i></a>
                                      </span>
                                    <span v-else>{{ tableData.item.log_id }}</span>
                                </template>

                                <template v-slot:cell(input_header)="tableData">
                                     <span v-if="tableData.item.input_header && tableData.item.input_header.length > 40" class="text-nowrap">
                                        {{ tableData.item.input_header.slice(0, 40) + '... ' }}
                                        <a v-b-popover.hover.html.bottom="tableData.item.input_header" class="elementPointed float-end" title="Input" size="sm"><i class="uil uil-eye font-size-18"></i></a>
                                      </span>
                                    <span v-else>{{ tableData.item.input_header }}</span>
                                </template>

                                <template v-slot:cell(response)="tableData">
                                     <span v-if="tableData.item.response && tableData.item.response.length > 40" class="text-nowrap">
                                        {{ tableData.item.response.slice(0, 40) + '... ' }}
                                        <a v-b-popover.hover.html.bottom="tableData.item.response" class="elementPointed float-end" title="Input" size="sm"><i class="uil uil-eye font-size-18"></i></a>
                                      </span>
                                    <span v-else>{{ tableData.item.response }}</span>
                                </template>

                                <template v-slot:cell(action)="tableData">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a href="javascript:void(0);" class="px-2 text-primary" title="Copy Content" @click="copyToClipboard(tableData.item)">
                                                <i class="uil uil-copy font-size-18"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>
